<template>
  <v-app>
    <v-content>
      <v-snackbar
          :id="snackbarId"
          v-model="snackbar.visibility"
          :color="snackbar.color"
          :bottom="snackbar.position === 'bottom'"
          :timeout="snackbar.timeout"
          :top="snackbar.position === 'top'"
      >
        {{ snackbar.message }}
        <v-btn
            color="primary--text"
            @click="snackbar.visibility = false"
            fab
            small
        >
          <v-icon x-small color="red">fas fa-times</v-icon>
        </v-btn>
      </v-snackbar>

<!--      <v-dialog v-model="loading" max-width="500" persistent>-->
<!--        <v-card>-->
<!--          <v-card-text class="pt-5">-->
<!--            <v-progress-linear-->
<!--                active-->
<!--                indeterminate-->
<!--                query-->
<!--                rounded-->
<!--                stream-->
<!--            ></v-progress-linear>-->
<!--          </v-card-text>-->
<!--        </v-card>-->
<!--      </v-dialog>-->
      <v-container fluid class="pa-0 ma-0  " style="height: 100%;background-color: transparent;">
        <WizardModal :showModal="showPrivacyPolicySwitch" @modalClosed="showPrivacyPolicySwitch=false"></WizardModal>
        <div class="main-container-c " :class="{'blur-content':showPrivacyPolicySwitch}">
          <div class="main-background  ">
            <img class="bg-circles aqua-circle" :src="require('../assets/new-design/Aqua.svg')" alt="">
            <img class="bg-circles green-circle" :src="require('../assets/new-design/Green.svg')" alt="">
            <img class="bg-circles purple-circle" :src="require('../assets/new-design/Purple.svg')" alt="">
          </div>
          <div class=" flex-navigation" style="z-index: 2;padding:5px 0">
            <v-container class="pa-0">
              <v-row no-gutters>
                <v-col class="text-center">
                  <router-link :to="{name:'dashboard'}">
                    <v-toolbar-title>
                      <img :src="require('../assets/simialogopng.png')" style="width: 125px;"
                           class="mt-4 mr-2">
                    </v-toolbar-title>
                  </router-link>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-center mr-12 my-2">
                  <WizardLoginRegisterButtonMobile
                      :activeBtn="loginRegisterBtn"
                      @changeMethod="setLoginRegisterWindow"
                      v-if="$vuetify.breakpoint.smAndDown"
                  ></WizardLoginRegisterButtonMobile>
                  <WizardLoginRegisterBtn :activeBtn="loginRegisterBtn"
                                          v-else
                                          @changeMethod="setLoginRegisterWindow"></WizardLoginRegisterBtn>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <v-container class="stepper-shell pa-0 flex-grow-1 " ref="stepper-shell"
                       :style="$vuetify.breakpoint.lgAndUp?'width:60%;z-index:2;position:relative;':'z-index:2;position:relative;'">
            <v-fade-transition>
              <WizardStepIndicator v-if="currentStep>1" :step="currentStep" class="my-2 mb-7"></WizardStepIndicator>
            </v-fade-transition>
            <div class="stepper" ref="stepper">
              <div class="stepper-item estedad-font step1" :class="{'stepper-item-colored':currentStep>1}" ref="step1">
                <template v-if="currentStep<=2">
                  <WizardStep1 @start="next" @skip="skip"></WizardStep1>
                </template>
              </div>
              <div class="stepper-item estedad-font step2" :class="{'stepper-item-colored':currentStep>1}" ref="step2"  v-if="needToGetCycle">
                <template v-if="currentStep<=3">
                  <v-card color="transparent" elevation="0" style="">
                    <div class="text-center mt-10" style="font-size: .8em;">
                      {{ $t('$vuetify.onBoarding.stepTwo.title') }}
                      <div>
                      <span style="" class="step-subtitle text-right">
                        <div class="step-subtitle-bullet"
                             :style="checkLang == 'fa' ? 'right: -8px' : 'left: -8px'"></div>
                        {{ $t('$vuetify.onBoarding.stepTwo.subtitle') }}
                      </span>
                      </div>
                    </div>
                    <v-card-text>
                      <v-row :justify="isMobile?'right':'center'" :align="isMobile?'right':'center'">
                        <SelectableWizardCard
                            v-for="(cycle,n) in cycles"
                            :key="n"
                            :ref="'select-card'"
                            animation="stagger-top"
                            @itemSelected="selectItem" :info="info" :item="cycle"
                            data-key="category"/>
                      </v-row>
                    </v-card-text>

                  </v-card>
                </template>
              </div>
              <div class="stepper-item estedad-font step3" :class="{'stepper-item-colored':currentStep>1}" ref="step3"  >
                <template v-if="currentStep >=2 && currentStep<=4">

                  <v-card color="transparent" elevation="0" style="">
                    <div class="text-center mt-10" style="font-size: .8em;">
                      {{ $t('$vuetify.onBoarding.stepThree.title') }}
                    </div>
                    <v-card-text>
                      <v-row :justify="isMobile?'right':'center'" :align="isMobile?'right':'center'">
                        <SelectableWizardCard
                            v-for="(gender,n) in genders"
                            :key="n"
                            @itemSelected="selectItem" :info="info" :item="gender"
                            data-key="gender"/>

                      </v-row>
                      <v-row align="center" justify="center">

                      </v-row>
                    </v-card-text>
                  </v-card>
                </template>
              </div>
              <div class="stepper-item estedad-font step4" :class="{'stepper-item-colored':currentStep>1}" ref="step4">
                <template v-if="currentStep>=3 && currentStep<=5">
                  <v-card color="transparent" elevation="0" style="">
                    <div class="text-center mt-10" style="font-size: .8em;">
                      {{ $t('$vuetify.onBoarding.stepFour.title') }}
                    </div>
                    <v-card-text>

                      <v-row class="mx-auto" justify="center"
                             align="center">

                        <template
                            v-for="(age,n) in agePeriods"
                        >
                          <SelectableWizardCard
                              @itemSelected="selectItem" :info="info" :item="age"
                              :no-image="true"
                              :key="n"
                              data-key="age"/>
                          <div class="cl" style="clear: both;width:100%;"
                               v-if="$vuetify.breakpoint.lgAndUp &&n!=0 && (n+1) % 3 == 0" :key="'key-'+n"></div>
                        </template>

                      </v-row>
                      <v-row align="center" justify="center">

                      </v-row>
                    </v-card-text>
                  </v-card>
                </template>
              </div>
              <div class="stepper-item estedad-font step5" :class="{'stepper-item-colored':currentStep>1}" ref="step5">
                <template v-if="currentStep>=4 && currentStep<=6">

                  <v-card color="transparent" elevation="0" style="">
                    <div class="text-center mt-10" style="font-size: .8em;">
                      {{ $t('$vuetify.onBoarding.stepFive.title') }}
                    </div>
                    <v-card-text>

                      <!--                    <v-row justify="center" align="center" style="width: 100%" class="mx-auto">-->
                      <v-row style="width: 100%" class="mx-auto" :justify="isMobile?'right':'center'"
                             :align="isMobile?'right':'center'">

                        <SelectableWizardCard
                            v-for="(relationship,n) in relationships"
                            :key="n"
                            @itemSelected="selectItem" :info="info" :item="relationship"
                            data-key="relationship"/>
                      </v-row>
                      <v-row align="center" justify="center">

                      </v-row>
                    </v-card-text>
                  </v-card>

                </template>
              </div>
              <div class="stepper-item estedad-font step6 " :class="{'stepper-item-colored':currentStep>1}" ref="step6" style="flex-direction:row;height:100%">
                <template v-if="currentStep>=5">
                  <div style="position: absolute;right:0;bottom: 30px;"
                       v-if="!isMobile">
                    <img style="width:85%" :src="require('../assets/new-design/furniture.svg')" alt="">
                  </div>

                  <div style="flex-grow: 2" v-if="!isMobile"></div>

                  <v-card color="transparent " :class="{'':!isMobile}" elevation="0" class="login-register-forms"
                          style="min-width:350px;">
                    <v-window v-model="loginWindow" :reverse="this.$store.getters.isRTL">
                      <v-window-item :value="1" class="">
                        <LoginComponent @changeWindow="updateWindow"
                                        :GALogin="'ga_login_new_preorder'"
                                        preorderLogin="true"
                                        @userLoggedIn="afterLogin"
                                        :subtitle="$t('$vuetify.onBoarding.titleLogin')"></LoginComponent>
                      </v-window-item>
                      <v-window-item :value="2">

                        <SignupComponent @changeWindow="updateWindow"
                                         :GASignup="'ga_signup_new_preorder'"
                                         preorderLogin="true"
                                         @userLoggedIn="afterLogin"
                                         wizard="true"
                                         :title="$t('$vuetify.onBoarding.titleLogin')"

                        ></SignupComponent>
                        <!--                  @userLoggedInAfterRegister="userLoggedIn"-->

                      </v-window-item>
                    </v-window>
                    <div style="min-height: 70px;">
                      <div class="mobile-methods-of-login " style="overflow: hidden!important;min-height: 35px;"
                           v-if="isMobile">
                        <div class="text-center mt-1 " style="font-size:.55em;color:#6d5aac;cursor: pointer;"
                             @click="animateLoginMethods">
                          {{ $t('$vuetify.onBoarding.loginWithDifferentWay') }}
                        </div>
                        <div class="justify-space-around login-methods my-1" ref="loginMethods">
                          <div class="mx-1  px-1 flex-grow-1">
                            <v-btn text fab small @click="loginBy('google')">
                              <img width="100%" :src="require('../assets/new-design/sociallogin/google.svg')" alt="">
                            </v-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-if="isMobile" class="login-methods-mobile"></div>
                  </v-card>
                  <div class="" style="flex-grow: 1" v-if="!isMobile"></div>
                  <div class="d-flex"
                       v-if="!isMobile"
                       style="height: 85%;justify-content: center;align-items: center;flex-direction: column;position: relative">
                    <div class="text-center login-decision-circle"
                        style="">
                      {{ $t('$vuetify.or') }}
                    </div>
                    <div style="width:1px;background-color: #b9a0f6;height:90%;margin-top:5%">
                    </div>
                  </div>
                  <div class="" style="width:35%;padding:10px;" v-if="!isMobile">
                    <div class="text-center">
                      <div style="font-size: .7em;color: #6d5aac">
                        {{ $t('$vuetify.onBoarding.loginWithDifferentWay') }}
                      </div>
                      <!--                      <v-card class="my-5 login-methods-item" color="transparent">-->
                      <!--                        <img :src="require('../assets/new-design/sociallogin/apple.svg')" alt="">-->
                      <!--                        <div style="color:#6d6d6d;font-size: .5em;position: relative;bottom: 10px;">حساب اپل-->
                      <!--                        </div>-->
                      <!--                      </v-card>-->
                      <v-card class="my-5 login-methods-item" color="transparent" @click="loginBy('google')">
                        <img :src="require('../assets/new-design/sociallogin/google.svg')" alt="">
                        <div style="color:#6d6d6d;font-size: .5em;position: relative;bottom: 10px;">
                          {{ $t('$vuetify.onBoarding.loginItems[0]') }}
                        </div>
                      </v-card>
                      <!--                      <v-card class="my-5 login-methods-item" color="transparent">-->

                      <!--                        <img :src="require('../assets/new-design/sociallogin/twitter.svg')" alt="">-->
                      <!--                        <div style="color:#6d6d6d;font-size: .5em;position: relative;bottom: 10px;">حساب توییتر-->
                      <!--                        </div>-->

                      <!--                      </v-card>-->
                    </div>

                  </div>
                </template>
              </div>
            </div>

            <!--            <v-fade-transition>-->
            <!--              <WizardIndicator-->
            <!--                  v-if="currentStep<=5 && currentStep>1"-->
            <!--                  :currentStep="currentStep"></WizardIndicator>-->
            <!--            </v-fade-transition>-->
            <WizardNavButtons v-if="currentStep < 6" :info="info" @next="next" @previous="previous"
                              :currentStep="currentStep"></WizardNavButtons>


          </v-container>
          <v-container
              :style="`transition:all .2s linear;width:${$vuetify.breakpoint.lgAndUp && currentStep ==1 ?'62%':shellWidth};z-index:2`"
              class="pt-0 mt-0 flex-grow-1">
            <div :style="`position:relative;text-align:right;`" class="ma-0">
              <v-row no-gutters>
                <v-col class="ma-0 pa-0" :class="$route.params.lang == 'en' ? 'text-left' : '' ">
                  <span :style="`font-size: .5em;width:100%;position:relative;`"
                        class="rules-and-conditions" @click="showPrivacyPolicy"
                        :class="{'active':currentStep==6 && loginRegisterBtn!='login'}"
                  >
                          {{ $t('$vuetify.onBoarding.terms') }}
                  </span>

                  <v-fade-transition>
                    <div class="arrow-left" v-if="currentStep==6 && loginRegisterBtn!='login'"
                         :style="checkLang == 'fa' ? 'right: -10px;top: 17px;' : '  left: -10px;transform: rotate(180deg);top: 25px;'"></div>
                    <!--                    <div class="arrow-left" ></div>-->
                  </v-fade-transition>
                </v-col>
                <v-col class="text-left" v-if="!$vuetify.breakpoint.xs"
                       :class="$route.params.lang == 'en' ? 'text-right' : '' ">
                  <a :href="item.link" v-for="(item,n) in socialLinks" :key="n" target="_blank">
                    <v-icon class="social-icons mx-2" size="18">{{ item.icon }}</v-icon>
                  </a>
                </v-col>
              </v-row>
              <!--              -->
              <!--              <div class="mr-0 " >-->
              <!--              -->
              <!--                <v-spacer></v-spacer>-->

              <!--                -->
              <!--              </div>-->
            </div>
          </v-container>
        </div>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
/*eslint-disable*/
import gsap from 'gsap';
import LoginComponent from '@/components/LoginComponentWizard.vue'
import SignupComponent from '@/components/SignUpComponentWizard.vue'
import SelectableWizardCard from "../components/wizard/SelectableWizardCard";
import WizardNavButtons from "../components/wizard/WizardNavButtons";
import WizardIndicator from "../components/wizard/WizardIndicator";
import WizardStep1 from "../components/wizard/WizardStep1";
import WizardLoginRegisterBtn from "../components/wizard/WizardLoginRegisterBtn";
import WizardLoginRegisterButtonMobile from "../components/wizard/WizardLoginRegisterButtonMobile";
import WizardStepIndicator from "../components/wizard/WizardStepIndicator";
import WizardModal from '../components/wizard/WizardModal'
import LS from "../LocalStorateAPI";
import {mapGetters} from 'vuex';
import {EventBus} from "@/event-bus";

export default {
  components: {
    LoginComponent,
    SignupComponent,
    SelectableWizardCard,
    WizardNavButtons,
    WizardIndicator,
    WizardStep1,
    WizardLoginRegisterBtn,
    WizardLoginRegisterButtonMobile,
    WizardStepIndicator,
    WizardModal
  },
  computed: {

    ...mapGetters({
      'loading': 'loading',
      'snackbarId': 'getSnackbarId'
    }),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    shellWidth() {
      return this.$vuetify.breakpoint.smAndDown ? '100%' : '70%';
    },
    checkLang() {
      return this.$route.params.lang == "fa" || this.$route.params.lang == undefined ? 'fa' : 'en';
    }
  },
  data() {
    return {
      snackbar: {
        visibility: false,
        color: 'info',
        position: 'bottom',
        timeout: 5000,
        mode: 'vertical',
        message: 'this is snackbar message',
      },
      showMethods: false,
      showPrivacyPolicySwitch: false,
      socialLinks: [
        {link: 'https://www.youtube.com/simiaroom', icon: 'fab fa-youtube'},
        {link: 'https://twitter.com/simiaroom', icon: 'fab fa-twitter'},
        {link: 'https://www.facebook.com/simiatalk', icon: 'fab fa-facebook'},
        {link: 'https://www.linkedin.com/company/simiaroom/', icon: 'fab fa-linkedin'},
        {link: 'https://www.instagram.com/simiaroomcom/', icon: 'fab fa-instagram'},
        {link: 'https://wa.me/00989302672054', icon: 'fab fa-whatsapp'},

      ],
      loginRegisterBtn: 'register',
      loginWindow: 2,
      relationships: [
            {
              title: this.$t('$vuetify.onBoarding.stepFive.relationships[0]'),
              image: require('../assets/new-design/relationship/single.svg'),
              val: 'مجرد',
            },
            {
              title: this.$t('$vuetify.onBoarding.stepFive.relationships[1]'),
              image: require('../assets/new-design/relationship/married.svg'),
              val: 'متاهل'
            },
            {
              title: this.$t('$vuetify.onBoarding.stepFive.relationships[2]'),
              image: require('../assets/new-design/relationship/relationship.svg'),
              val: 'شریک عاطفی'
            },
            {
              title: this.$t('$vuetify.onBoarding.stepFive.relationships[3]'),
              image: require('../assets/new-design/relationship/other.svg'),
              val: 'موارد دیگر'
            },
          ],
      agePeriods: [
            {
              title: this.$t('$vuetify.onBoarding.stepFour.agePeriods[0]'),
              val: 'کمتر از 18',
            },
            {
              title: '19 - 25',
              val: '19 - 25',
            },
            {
              title: '26 - 30',
              val: '26 - 30',
            },
            {
              title: '31 - 35',
              val: '31 - 35',
            },
            {
              title: '36 - 45',
              val: '36 - 45',
            },
            {
              title: this.$t('$vuetify.onBoarding.stepFour.agePeriods[1]'),
              val: 'بیشتر از 45',
            },
          ],
      genders: [
            {
              title: this.$t('$vuetify.onBoarding.stepThree.gender[0]'),
              image: require('../assets/new-design/genders/male.svg'),
              val: 'مرد'
            },
            {
              title: this.$t('$vuetify.onBoarding.stepThree.gender[1]'),
              image: require('../assets/new-design/genders/female.svg'),
              val: 'زن',
            },
            {
              title: this.$t('$vuetify.onBoarding.stepThree.gender[2]'),
              image: require('../assets/new-design/genders/other.svg'),
              val: 'موارد دیگر'
            },
          ],
      cycles: [
            {
              title: this.$t('$vuetify.onBoarding.stepTwo.cycles[0]'),
              image: require('../assets/new-design/cycles/individual.svg'),
              val: 'فردی',
            },
            {
              title: this.$t('$vuetify.onBoarding.stepTwo.cycles[1]'),
              image: require('../assets/new-design/cycles/relation.svg'),
              val: 'رابطه و زوج درمانی',
            },
            {
              title: this.$t('$vuetify.onBoarding.stepTwo.cycles[2]'),
              image: require('../assets/new-design/cycles/teen.svg'),
              val: 'کودک و نوجوان',
            },
          ],
      currentStep: 1,
      step1: null,
      step2: null,
      timeline: null,
      info: {
            category: null,
            gender: null,
            age: null,
            relationship: null
          }
      ,
      stepVal: {
        step2: 'category',
        step3: 'gender',
        step4: 'age',
        step5: 'relationship'
      },
      needToGetCycle:true,

    }
  },
  created() {
    EventBus.$on('setLoading', this.setLoading);
    if (this.$route.params.lang == undefined) {
      EventBus.$on('notify', this.showSnackbar);
    }
  },
  beforeDestroy() {
    EventBus.$off('setLoading', this.setLoading);
    // EventBus.$off('notify', this.showSnackbar);
  },
  mounted() {
    if (this.getCycleFromRoute(this.$route.query.cycle) != false){
      this.needToGetCycle = false;
    }
    if (this.$route.query.start === "2") {
      this.next();
    }
    // this.$refs.step1 = this.$refs.step1
    // this.$refs.step2 = this.$refs.step2
    // gsap = new gsap.timeline();
    gsap.fromTo(this.$refs.step1, .5, {x: 0, opacity: 0}, {
      x: 0,
      opacity: 1,
      display: 'flex'
    });
  },
  methods: {
    skip(){
      this.tempDisableOverflow()
      gsap.fromTo(this.$refs[`step1`], {
        // position: 'absolute'
      }, {
        y: '-100%',
        opacity: 1,
        display: 'none',
        // position: 'absolute',
      });

      gsap.fromTo(this.$refs[`step6`], {
        y: '100%',
        opacity: 1,
        display: 'flex',
        // position: 'absolute',
        duration: 0
      }, {
        y: 0,
        opacity: 1,
        display: 'flex',
        duration: .6,

        // position: 'absolute',
      });
      this.currentStep=6;
    },
    showSnackbar(color = 'success', timeout = 5000, message = 'عملیات با موفقیت انجام شد.', snackbarId = null) {
      this.snackbar.visibility = true;
      this.snackbar.color = color;
      this.snackbar.position = 'bottom';
      this.snackbar.timeout = timeout;
      this.snackbar.mode = 'vertical';
      this.snackbar.message = message;

      if (snackbarId != null) {
        this.$store.commit('SET_SNACKBAR_ID', snackbarId)
      }

    },
    setLoading(loading) {
      this.$store.commit('setLoading', loading);
    },
    animateLoginMethods() {
      if (this.showMethods) {
        gsap.fromTo(this.$refs.loginMethods, {
          height: '40px',
          opacity: 1,
          display: 'flex',
          duration: 0
        }, {
          height: '0px',
          opacity: 0,
          duration: .5,
          display: 'none'
        });
        this.showMethods = false;
      } else {
        gsap.fromTo(this.$refs.loginMethods, {
          height: '0px',
          opacity: 0,
          display: 'none',
          duration: 0
        }, {
          height: '40px',
          opacity: 1,
          duration: .5,
          display: 'flex'
        });
        this.showMethods = true;
      }

    },
    loginBy(target) {
      switch (target) {
        case 'google':
          window.location.href = 'https://api.simiaroom.com/api/v1/auth/social/google';
      }
    },
    afterLogin() {
      console.log('LOGGED IN!!!')
      this.$store.dispatch('postSurveyAnswers', this.info).then(() => {
        // this.$router.push('preorder/sign/68');
      }).finally(() => {
        if (this.$route.query.package_id != null){
          this.$router.push(`preorder/sign/${this.$route.query.package_id}`);
        }else{
          this.$router.push('preorder/sign/68');
        }
      });
    },
    showPrivacyPolicy() {
      this.showPrivacyPolicySwitch = true
    },
    tempDisableOverflow() {
      this.$refs.stepper.style.overflow = 'hidden';
      setTimeout(() => {
        this.$refs.stepper.style.overflow = 'auto';
      }, 700)
    },
    setLoginRegisterWindow(win) {
      this.loginRegisterBtn = win;
      if (win == 'register') {
        this.loginWindow = 2;

      } else {
        this.loginWindow = 1;
      }
    },
    updateWindow(variable) {
      this.loginWindow = variable
      this.loginRegisterBtn = variable == 1 ? 'login' : 'register';

    },
    selectItem(destinationKey, target, multiselect = false) {
      if (!multiselect) {
        this.info[destinationKey] = target;
      }
      setTimeout(this.next, 300)
    },
    log(x) {
      console.log(x)
    },
    next() {
      this.tempDisableOverflow()
      let key = 'step' + this.currentStep;
      if (this.stepVal.hasOwnProperty(key)) {
        if (this.info[this.stepVal[key]] == null) {
          return;
        }
      }
      if (this.currentStep == 1) {
        if (this.$route.query.cycle){
          if (this.getCycleFromRoute(this.$route.query.cycle) != false){
            this.selectItem('category' ,this.getCycleFromRoute(this.$route.query.cycle),false )
          }
        }
        gsap.to(this.$refs['stepper-shell'], .3, {width: this.shellWidth});
      }



      if (this.currentStep < 5) {
        let x = 0;
        if (this.currentStep != 1) {
          x = 50;
        }

        if (!this.$vuetify.rtl) x = -x
        gsap.to(this.$refs[`step${this.currentStep}`], .5, {x, opacity: 0, display: 'none'});
        gsap.fromTo(this.$refs[`step${this.currentStep + 1}`], {x: -x, opacity: 0, display: 'none'}, {
          x: 0,
          opacity: 1,
          display: 'flex',
        }, '<');
        this.currentStep++;
      } else if (this.currentStep == 5) {
        this.tempDisableOverflow()
        gsap.fromTo(this.$refs[`step${this.currentStep}`], {
          // position: 'absolute'
        }, {
          y: '-100%',
          opacity: 1,
          display: 'none',
          // position: 'absolute',
        });
        gsap.fromTo(this.$refs[`step${this.currentStep + 1}`], {
          y: '100%',
          opacity: 1,
          display: 'flex',
          // position: 'absolute',
          duration: 0
        }, {
          y: 0,
          opacity: 1,
          display: 'flex',
          duration: .6,

          // position: 'absolute',
        });
        this.currentStep++;
      }

    },
    getCycleFromRoute(val){
      switch (val) {
        case "childandteen":
          return 'کودک و نوجوان'
        case "individual":
          return 'فردی'
        case 'relationship':
          return 'رابطه و زوج درمانی'
        case "child":
          return "'کودک و نوجوان'"
        case "psychiatrist":
          return "روانپزشکی"
        default:
          return false
      }
    },
    previous() {
      this.tempDisableOverflow()
      if (this.currentStep > 1) {
        let x = 50;
        if (this.currentStep == 3){
          if (this.getCycleFromRoute(this.$route.query.cycle) != false){
            this.currentStep--
            gsap.to(this.$refs[`step3`], .5, {
              x: -x,
              opacity: 0,
              display: 'none'
            }, '<');
            gsap.to(this.$refs[`step2`], 0, {
              x: -x,
              opacity: 0,
              display: 'none'
            }, '<');
          }
        }



        if (!this.$vuetify.rtl) x = -x;

        if (this.currentStep == 2) {
          gsap.to(this.$refs['stepper-shell'], {width: this.isMobile ? '100%' : '60%'});
          x = 0;
        }

        if (this.currentStep < 6) {
          gsap.fromTo(this.$refs[`step${this.currentStep}`], {x: 0, opacity: 1, display: 'flex'}, {
            x: -x,
            opacity: 0,
            display: 'none'
          });
          gsap.to(this.$refs[`step${this.currentStep - 1}`], .5, {
            x: 0,
            opacity: 1,
            display: 'flex'
          }, '<');
          this.currentStep--;
        } else if (this.currentStep == 6) {
          this.tempDisableOverflow();
          gsap.to(this.$refs[`step${this.currentStep}`], .5, {y: '100%', opacity: 1, display: 'none'});
          gsap.fromTo(this.$refs[`step${this.currentStep - 1}`], {
            y: '-100%',
            opacity: 1,
            display: 'flex',
            duration: 0
          }, {
            y: 0,
            opacity: 1,
            display: 'flex',
            duration: .6
          });
          this.currentStep--;
        }
      }
    }
  },
  watch: {
    showPrivacyPolicySwitch() {},
    currentStep(newVal, oldVal) {
      if (oldVal < newVal) {
        if (newVal === 2 && !this.isMobile) {
          let items = this.$refs['select-card'].map(item => item.$el);
          gsap.from(items, .3, {y: -8, opacity: 0, stagger: -.05});
        }
      }
      if (newVal === 6) {
        LS.set('surveyData', this.info)
        gsap.to(this.$refs.loginMethods, .1, {
          display: 'none',
          height: 0,
        });
      }
    }
  },

}
</script>

<style>

.stepper-shell {
  /*width: 60%;*/
  height: 80%;
  max-height: 700px;
  box-shadow: 0 3px 25px 0 rgba(0, 0, 0, 0.06);
  background-color: #fff;
  border-radius: 20px;
}

@media screen and (max-width: 967px ) {
  .stepper-shell {
    max-width: 95% !important;
  }
}

@media screen and (max-width: 500px) {
  .login-register-forms {
    width: 100%;
  }
}

.stepper {
  height: 90%;
  margin: 10px 10px 0 10px;
  position: relative;
  border-radius: 20px;
  overflow-x: hidden !important;
}

.stepper-item {
  width: 100%;
  min-height: 100%;


  margin: 0 auto;
  position: absolute;
  opacity: 0;
  display: none;
  /*display: flex!important;*/
  justify-content: center;
  flex-direction: row;
  align-items: center;
  transition: background-color .3s linear;
}

.stepper-item-colored {
  background-color: #f5faff;
}

.main-container-c {
  font-size: 24px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 100% !important;
  flex-direction: column;
  z-index: 3 !important;
  transition: all .3s linear;
}


.flex-navigation {
  width: 100%;
  z-index: 10;
  align-self: flex-start;

}

.bg-circles {
  position: absolute;
  z-index: 2;
  filter: blur(46px);
  -webkit-filter: blur(46px);
}

.bg-circles.aqua-circle {
  top: -10%;
  left: -5%;
  width: 30%;
}

.bg-circles.green-circle {
  bottom: 12%;
  right: -10%;
  width: 20%;
}

.bg-circles.purple-circle {
  bottom: -15%;
  left: -5%;
  width: 20%;
}

.main-background {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #f9ffff !important;
  z-index: 1;
}


.login-methods-item:hover {
  background-color: white !important;
  box-shadow: 0 3px 14px 0 rgba(0, 0, 0, 0.07);
}

.login-methods-item {
  width: 45%;
  margin: 0 auto;
  border-radius: 10px !important;
  border: solid 2px #dbdbdb !important;
  transition: all .3s linear !important;
  cursor: pointer;
  box-shadow: none;
}

.login-decision-circle {
  width: 30px;
  height: 30px;
  background-color: #f5faff;
  border: 1px solid #6d5aac;
  border-radius: 50px;
  position: absolute;
  top: 10%;
  font-size: .5em;
  line-height: 30px;
  color: #574596
}

.login-methods-mobile {
  width: 100%;
  height: 50px;
}

.step-subtitle:before {
}

.step-subtitle {
  color: #6d6d6d;
  font-size: .6em;
  position: relative;
}

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Medium.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Medium.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Medium.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Medium.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Medium.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Medium.svg#Estedad-FD-Medium') format('svg');*/
/*  font-weight: 500;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-SemiBold.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-SemiBold.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-SemiBold.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-SemiBold.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-SemiBold.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-SemiBold.svg#Estedad-FD-SemiBold') format('svg');*/
/*  font-weight: 600;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Bold.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Bold.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Bold.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Bold.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Bold.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Bold.svg#Estedad-FD-Bold') format('svg');*/
/*  font-weight: bold;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Thin.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Thin.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Thin.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Thin.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Thin.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Thin.svg#Estedad-FD-Thin') format('svg');*/
/*  font-weight: 100;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-ExtraBold.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-ExtraBold.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraBold.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraBold.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraBold.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraBold.svg#Estedad-FD-ExtraBold') format('svg');*/
/*  font-weight: bold;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Black.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-Black.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Black.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Black.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Black.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-Black.svg#Estedad-FD-Black') format('svg');*/
/*  font-weight: 900;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

/*@font-face {*/
/*  font-family: 'Estedad-FD';*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-ExtraLight.eot');*/
/*  src: url('../assets/fonts/estedad/Estedad-FD-ExtraLight.eot?#iefix') format('embedded-opentype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraLight.woff2') format('woff2'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraLight.woff') format('woff'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraLight.ttf') format('truetype'),*/
/*  url('../assets/fonts/estedad/Estedad-FD-ExtraLight.svg#Estedad-FD-ExtraLight') format('svg');*/
/*  font-weight: 200;*/
/*  font-style: normal;*/
/*  font-display: swap;*/
/*}*/

@font-face {
  font-family: 'Estedad-FD';
  src: url('../assets/fonts/estedad/Estedad-FD-Regular.eot');
  src: url('../assets/fonts/estedad/Estedad-FD-Regular.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/estedad/Estedad-FD-Regular.woff2') format('woff2'),
  url('../assets/fonts/estedad/Estedad-FD-Regular.woff') format('woff'),
  url('../assets/fonts/estedad/Estedad-FD-Regular.ttf') format('truetype'),
  url('../assets/fonts/estedad/Estedad-FD-Regular.svg#Estedad-FD-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Estedad-FD';
  src: url('../assets/fonts/estedad/Estedad-FD-Light.eot');
  src: url('../assets/fonts/estedad/Estedad-FD-Light.eot?#iefix') format('embedded-opentype'),
  url('../assets/fonts/estedad/Estedad-FD-Light.woff2') format('woff2'),
  url('../assets/fonts/estedad/Estedad-FD-Light.woff') format('woff'),
    /*url('../assets/fonts/estedad/Estedad-FD-Light.ttf') format('truetype'),*/ url('../assets/fonts/estedad/Estedad-FD-Light.svg#Estedad-FD-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Medium.ttf");
}

.estedad-font {
  font-family: 'Estedad-FD' !important;
}

.arrow-left {
  width: 0;
  height: 0;
  position: absolute;

  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 6px solid #4576d8;
}

.step-subtitle-bullet {
  background-color: #707070;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  position: absolute;
  top: 8px;
  /*right: -8px;*/
}

.social-icons {
  transition: all .3s linear;
}

.social-icons:hover {
  color: black;
}

.rules-and-conditions {
  transition: all .3s linear;
  color: #78a5ff;
  cursor: pointer;
  margin-right: -5px;
}

.rules-and-conditions.active {
  color: #4576d8 !important;
  margin-right: 0px;
}

.rules-and-conditions:hover {
  color: #4576d8 !important;
}

.blur-content {
  /*filter: blur(5px);*/
  /*-webkit-filter: blur(5px);*/
}

.mobile-methods-of-login {
  border: solid 1px #6d5aac;
  border-radius: 10px !important;
  width: 90%;
  margin: 0 auto;
}

.login-methods {
  display: flex;
  line-height: 30px;
  text-align: center;
}
</style>
